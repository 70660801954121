import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Reveal from "../utils/Reveal"

import Button from "../atoms/Button"
import Card from "../atoms/Card"
import FlexGrid from "../atoms/FlexGrid"
import Box from "../atoms/Box"
import Flex from "../atoms/Flex"
import Filter from "../atoms/Filter"
import Sort from "../molecules/Sort"

const NUM_TO_LOAD = 6

const FilteredGrid = ({
  flexGrid,
  children,
  loadMoreBtnStyles,
  centerFilter,
  filters = [],
  filtersStyles,
  filterSortLocation,
  sort,
}) => {
  const location = centerFilter // from Portfolio Grids
    ? "center"
    : filterSortLocation // from Team Grids
    ? filterSortLocation
    : filtersStyles?.filterLocation // from Blog Grids, Image Grids, Logo Grids
    ? filtersStyles?.filterLocation
    : "left"
  const isSplit = location === "split" || location === "splitReverse"

  const [numLoaded, setNumLoaded] = useState(NUM_TO_LOAD)
  const childrenArr = React.Children.toArray(children)
  const [filteredChildrenArr, setFilteredChildrenArr] = useState(childrenArr)
  const [selectedFilters, setSelectedFilters] = useState({})
  const [currentSort, setCurrentSort] = useState(null)

  const loadMore = startWith => {
    const newNum = startWith + NUM_TO_LOAD
    setNumLoaded(newNum)
  }

  const getSlicedChildren = () => {
    if (
      !currentSort &&
      (!sort?.defaultSortSelection ||
        sort?.defaultSortSelection === "customOrderBool")
    ) {
      if (loadMoreBtnStyles?.showBtn) {
        return filteredChildrenArr.slice(0, numLoaded)
      }
      return filteredChildrenArr
    } else {
      if (loadMoreBtnStyles?.showBtn) {
        return sortFilteredArray().slice(0, numLoaded)
      }
      return sortFilteredArray()
    }
  }

  const updateFilters = (tag, filter) => {
    setSelectedFilters(prevState => {
      const updatedState = {
        ...prevState,
        [filter.id]: tag?.[filter.id],
      }
      if (!tag?.[filter.id]) {
        delete updatedState[filter.id]
      }

      return updatedState
    })
  }

  const updateGrid = () => {
    const selFilters = Object.values(selectedFilters).filter(tag => tag)
    if (selFilters.length > 0) {
      setFilteredChildrenArr(
        prevFilteredChildrenArr => {
          return prevFilteredChildrenArr.filter(child =>
            Object.keys(selectedFilters).every(SelFilKey => {
              if (!selectedFilters[SelFilKey]) {
                return true
              }
              return child.props?.tags
                ?.map(({ name }) => name)
                .includes(selectedFilters[SelFilKey])
            })
          )
        },
        loadMore(0) //reseting initial items loaded amount
      )
    }
  }

  const sortFilteredArray = () => {
    const sortSelection = currentSort
      ? currentSort[sort?.id]
      : sort?.defaultSortSelection
      ? sort?.optionsObj[sort.defaultSortSelection]
      : null
    let sortedArr
    switch (sortSelection) {
      case "First Name":
        sortedArr = [...filteredChildrenArr].sort((a, b) =>
          a.props.name.localeCompare(b.props.name)
        )
        return sortedArr
        break
      case "Last Name":
        sortedArr = [...filteredChildrenArr].sort((a, b) => {
          const romanNumerals = [
            "I",
            "II",
            "III",
            "IV",
            "V",
            "VI",
            "VII",
            "VIII",
            "IX",
            "X",
          ]
          const aNameArr = a.props.name.split(" ")
          const aLastItem = aNameArr[aNameArr.length - 1]
          const aLastName =
            isNaN(Number(aLastItem)) && !romanNumerals.includes(aLastItem) // make sure last item in array is not a number or roman numeral
              ? aLastItem
              : aNameArr.length - 2 >= 0
              ? aNameArr[aNameArr.length - 2] // if the last item is a number or roman numeral, use the second-to-last item
              : aNameArr[0]
          const bNameArr = b.props.name.split(" ")
          const bLastItem = bNameArr[bNameArr.length - 1]
          const bLastName =
            isNaN(Number(bLastItem)) && !romanNumerals.includes(bLastItem) // make sure last item in array is not a number or roman numeral
              ? bLastItem
              : bNameArr.length - 2 >= 0
              ? bNameArr[bNameArr.length - 2] // if the last item is a number or roman numeral, use the second-to-last item
              : bNameArr[0]
          return aLastName.localeCompare(bLastName)
        })
        return sortedArr
        break
      case "Position Title":
        sortedArr = [...filteredChildrenArr].sort((a, b) =>
          a.props.positionTitle.localeCompare(b.props.positionTitle)
        )
        return sortedArr
        break
      case "Custom Order":
      case sort?.customOrderTitle:
      default:
        return filteredChildrenArr
    }
  }

  const handleJustification = alignmentStr => {
    switch (alignmentStr) {
      case "center":
        return "center"
      case "left":
        return "flex-start"
      case "right":
        return "flex-end"
      case "split":
      case "splitReverse":
        return "space-between"
      default:
        return "flex-start"
    }
  }

  useEffect(() => {
    //this useEffect allow the grid to update children content when comp do not uses the filter prop
    if (!filters?.length) {
      setFilteredChildrenArr(
        childrenArr,
        loadMore(0) //reseting initial items loaded amount
      )
    }
  }, [children])

  useEffect(() => {
    //useEffect to update grid when filters option is turned on
    setFilteredChildrenArr(
      childrenArr,
      loadMore(0) //reseting initial items loaded amount)
    )
    updateGrid()
  }, [selectedFilters])

  return (
    <div>
      {(filters || sort?.useSort) && (
        <Reveal>
          <Flex
            flexDirection={
              filterSortLocation === "splitReverse" ? "row-reverse" : "row"
            }
            justifyContent={{
              _: handleJustification(isSplit ? "center" : location),
              md: handleJustification(location),
            }}
            flexWrap="wrap"
            mb={6}
          >
            <Flex
              flexDirection="row"
              justifyContent={{
                _: handleJustification(isSplit ? "center" : location),
                md: handleJustification(location),
              }}
              flexWrap="wrap"
            >
              {filters.map(filter => (
                <Filter
                  location={location}
                  onChange={tag => {
                    updateFilters(tag, filter)
                  }}
                  gridItems={children}
                  {...filter}
                  {...filtersStyles}
                />
              ))}
            </Flex>
            {sort?.useSort && (
              <Sort
                location={location}
                setCurrentSort={setCurrentSort}
                allLabel="Sort By:"
                showFilterLabel={filtersStyles?.showFilterLabel}
                {...sort}
              />
            )}
          </Flex>
        </Reveal>
      )}
      <FlexGrid {...flexGrid}>
        {getSlicedChildren().map((child, i) => (
          <div key={`${child.props.forwardKey}-${i}`}>{child}</div>
        ))}
      </FlexGrid>
      {loadMoreBtnStyles?.showBtn && filteredChildrenArr.length > numLoaded && (
        <Flex mt={8} flexDirection="column" {...loadMoreBtnStyles}>
          <Button
            aria-label="Load more items"
            text="Load More"
            onClick={() => loadMore(numLoaded)}
          />
        </Flex>
      )}
    </div>
  )
}

FilteredGrid.strapiProps = {
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  card: PropTypes.shape(Card.strapiProps),
  filters: PropTypes.arrayOf(PropTypes.shape(Filter.strapiProps)),
  filter: PropTypes.shape(Filter.strapiProps),
}

export default FilteredGrid
