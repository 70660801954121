import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import Reveal from "../utils/Reveal"

import Card from "../atoms/Card"
import Section from "../atoms/Section"
import FlexGrid from "../atoms/FlexGrid"
import Button from "../atoms/Button"
import Flex from "../atoms/Flex"
import Box from "../atoms/Box"

import InfoCard from "../molecules/InfoCard"
import SectionTitle from "../molecules/SectionTitle"

const CtaInfoCards = ({
  section,
  sectionTitle,
  flexGrid,
  CICFlexGrid,
  infoCards,
  button,
  card,
  ctaInfoCardsIconSize,
  iconSize,
  ctaInfoCardsAlign,
  cardsAlign,
  ctaInfoCardsIconLocation,
  iconLocation,
}) => {
  flexGrid = CICFlexGrid || flexGrid
  return (
    <Section {...section}>
      <Flex flexDirection={{ _: "column", lg: "row" }}>
        {sectionTitle && (
          <Reveal>
            <Box mr={8}>
              <SectionTitle {...sectionTitle} mb={6} maxWidth={42} />
              {button && (
                <Flex
                  mt={4}
                  mb={4}
                  flexDirection="column"
                  alignItems={sectionTitle.align || "left"}
                >
                  <Button
                    aria-label="CTA button in info card element"
                    {...button}
                  />
                </Flex>
              )}
            </Box>
          </Reveal>
        )}
        <Flex width="100%" justifyContent="space-between">
          <FlexGrid flex={"1 1 auto"} {...flexGrid}>
            {infoCards.map((infoCard, i) => (
              <InfoCard
                mx={
                  ctaInfoCardsAlign === "center" || cardsAlign === "center"
                    ? "auto"
                    : 0
                }
                iconLocation={ctaInfoCardsIconLocation || iconLocation}
                iconSize={ctaInfoCardsIconSize || iconSize}
                align={ctaInfoCardsAlign || cardsAlign}
                {...infoCard}
                key={i}
                card={card}
              />
            ))}
          </FlexGrid>
        </Flex>
      </Flex>
    </Section>
  )
}

export default CtaInfoCards

CtaInfoCards.propTypes = {
  strapi_id: PropTypes.number.isRequired,
  section: PropTypes.shape(Section.strapiProps).isRequired,
  sectionTitle: PropTypes.shape(SectionTitle.strapiProps).isRequired,
  flexGrid: PropTypes.shape(FlexGrid.strapiProps),
  infoCards: PropTypes.arrayOf(PropTypes.shape(InfoCard.strapiProps)),
  cardsAlign: PropTypes.oneOf(["left", "center"]).isRequired,
  iconLocation: PropTypes.oneOf(["top", "left"]).isRequired,
  card: PropTypes.shape(Card.strapiProps).isRequired,
}

export const query = graphql`
  fragment CtaInfoCards on STRAPI__COMPONENT_SECTIONS_CTA_INFO_CARDS {
    strapi_id
    CTAINFOCARDSSection: section {
      ...Section
    }
    sectionTitle {
      ...SectionTitle
    }
    CICFlexGrid: flexGrid {
      ...FlexGrid
    }
    infoCards {
      ...InfoCard
    }
    button {
      ...Button
    }
    card {
      ...Card
    }
    ctaInfoCardsAlign: cardsAlign
    ctaInfoCardsIconLocation: iconLocation
    ctaInfoCardsIconSize: iconSize
  }
`
