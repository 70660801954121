import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import styled, { ThemeContext } from "styled-components"
import { compose, layout } from "styled-system"

import { animation } from "../../lib/styled-system/system"

import Link from "../utils/Link"
import Image from "../utils/Image"
import { hexToRGBA } from "../../lib/color-helpers"

import BigImageCard from "./BigImageCard"
import Box from "../atoms/Box"
import Card from "../atoms/Card"
import Flex from "../atoms/Flex"
import RichText from "../atoms/RichText"
import { Title } from "../atoms/Typography"
import TruncateText from "../utils/Truncate"

const TextBox = styled(
  ({
    title,
    descriptionRichText,
    className,
    textClassName,
    textAlign,
    imageRatio,
    lineClamp,
    ...rest
  }) => {
    return (
      <Box
        display={textAlign === "center" ? "flex" : "block"}
        flexDirection="column"
        justifyContent="flex-start"
        width="100%"
        textAlign={textAlign === "center" ? "center" : "left"}
        className={className}
        {...rest}
      >
        <Title
          transitionProperty="opacity"
          transitionTimingFunction="ease"
          transitionDuration="md"
          variant="h3"
          mb={imageRatio === "oneOne" ? 3 : 0}
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {title}
        </Title>
        {descriptionRichText?.content && (
          <TruncateText lineClamp={lineClamp}>
            <StyledRichText
              transitionProperty="opacity"
              transitionTimingFunction="ease"
              transitionDuration="md"
              className={textClassName}
              textAlign={textAlign}
              {...descriptionRichText}
            />
          </TruncateText>
        )}
      </Box>
    )
  }
)`
  ${animation}
`

const StyledLink = styled(Link)`
  &:hover {
    & .image {
      transform: scale(1.05);
    }
  }
`

const StyledCard = styled(Card)`
  & .text-box {
    opacity: ${props => (props.textVisibility !== "onHover" ? 1 : 0)};
  }

  &:hover {
    & .text-box {
      ${props => (props.textVisibility === "onHover" ? "opacity: 1" : null)};
    }
    & .truncate-description-text {
      opacity: ${props => (props.textVisibility === "onHover" ? 1 : 0.75)};
    }
  }
`

const StyledRichText = styled(RichText)`
  ${animation}
`

const StyledImage = styled(Image)`
  ${compose(layout)}
`

const defaultCard = {
  borderRadius: "none",
  boxShadow: "md",
  bg: 0,
  theme: "light",
  useCard: false,
}

const getImageRatio = imageRatio => {
  switch (imageRatio) {
    case "fourThree":
      return 0.75
    case "oneOne":
      return 1
    case "threeTwo":
      return 0.66
    case "sixteenNine":
      return 0.5625
    default:
      return 1
  }
}

const getTextBoxMinHeight = imageRatio => {
  switch (imageRatio) {
    case "oneOne":
      return 43
    case "fourThree":
      return 55
    case "threeTwo":
      return 60
    case "sixteenNine":
      return 70
    default:
      return 43
  }
}

const getTextBoxPercentageScale = columns => {
  switch (columns) {
    case 4:
      return 40
    case 3:
      return 40
    case 2:
      return 33
    case 1:
      return 20
    default:
      return 33
  }
}

const ImageCard = ({
  title,
  descriptionRichText,
  image,
  link,
  alignText,
  card,
  columns,
  textBackground,
  textVisibility,
  imageRatio,
}) => {
  //setting a default value for card prop
  card =
    card && card.constructor === Object && Object.keys(card).length > 0
      ? card
      : defaultCard

  const currentTheme = useContext(ThemeContext)

  const bgColor =
    card?.useCard === true
      ? currentTheme?.themes[card?.theme]?.colors?.background[card?.bg]
      : "#FFFFFF"
  const bgStartColor = hexToRGBA(bgColor, 100)
  const bgMidColor = hexToRGBA(bgColor, 90)
  const bgEndColor = hexToRGBA(bgColor, 0)

  const [ratio, setRatio] = useState(getImageRatio(imageRatio))

  const showText = textVisibility !== "none"
  const showGradient = textBackground === "gradient" && showText
  const hasDescription =
    descriptionRichText?.content?.blocks?.[0]?.text?.length !== 0 ||
    (descriptionRichText?.content?.internal?.content &&
      JSON.parse(descriptionRichText.content.internal.content)?.blocks?.[0]
        ?.text?.length !== 0)

  const columnKeys = Object.keys(columns)
  const columnBreakpoint = columnKeys[columnKeys.length - 1]

  useEffect(() => {
    setRatio(getImageRatio(imageRatio))
  }, [imageRatio])

  if (card?.useCard === true && textBackground === "solid") {
    return (
      <BigImageCard
        title={title}
        descriptionRichText={descriptionRichText}
        image={image}
        link={link}
        alignText={alignText}
        card={card}
        textBackground={textBackground}
        textVisibility={textVisibility}
        imageWidth="full"
        imageRatio={ratio}
        overlayOpacity={1}
        lineClamp={100}
      />
    )
  }
  return (
    <StyledLink optional {...link}>
      <StyledCard
        aria-label="Card link"
        {...card}
        bg={null} //avoiding card to set bg color
        ratio={ratio}
        imageGridComponent={true}
        position="relative"
        textVisibility={textVisibility}
        textBackground={textBackground}
      >
        {card?.useCard === true && textBackground === "gradient" && (
          <Flex height="100%" width="100%">
            <Box height="100%" width="100%">
              {image && (
                <Box height="100%" width="100%">
                  <Box
                    className="image"
                    transitionProperty="height, transform"
                    transitionTimingFunction="ease"
                    transitionDuration="md"
                    height={
                      textVisibility === "visible" && textBackground === "solid"
                        ? {
                            _: "60%",
                            [columnBreakpoint]: `${
                              100 -
                              getTextBoxMinHeight(imageRatio) /
                                getTextBoxPercentageScale(
                                  columns[columnBreakpoint]
                                )
                            }%`,
                          }
                        : "100%"
                    }
                  >
                    <StyledImage {...image} style={{ height: "100%" }} />
                  </Box>
                  <Box
                    transitionProperty="opacity"
                    transitionTimingFunction="ease"
                    transitionDuration="md"
                    position="absolute"
                    opacity={showGradient ? 1 : 0}
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                    background={`linear-gradient(to top, ${bgStartColor} 0%,${bgMidColor} 30%, ${bgEndColor}) 100%`}
                  />
                </Box>
              )}
              {showText && (title || hasDescription) && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <TextBox
                    transitionProperty="opacity"
                    transitionTimingFunction="ease"
                    transitionDuration="md"
                    textAlign={alignText === "center" ? "center" : "left"}
                    textClassName="truncate-description-text"
                    className="text-box"
                    title={title}
                    descriptionRichText={descriptionRichText}
                    bg={
                      textBackground === "gradient"
                        ? "transparent"
                        : `background.${card.bg}`
                    }
                    position="absolute"
                    width="100%"
                    left="0px"
                    bottom="0px"
                    overflow="hidden"
                    py={4}
                    px={5}
                    imageRatio={imageRatio}
                    lineClamp={
                      getImageRatio(imageRatio) === 1
                        ? 3
                        : getImageRatio(imageRatio) === 0.75
                        ? 2
                        : 1
                    }
                  />
                </div>
              )}
            </Box>
          </Flex>
        )}
        {card?.useCard === false && (
          <Flex height="100%" width="100%">
            <Box height="100%" width="100%">
              {image && (
                <Box
                  transitionProperty="height, transform"
                  transitionTimingFunction="ease"
                  transitionDuration="md"
                  height="100%"
                  className="image"
                >
                  <StyledImage {...image} style={{ height: "100%" }} />
                </Box>
              )}
              {showText && (title || hasDescription) && (
                <div style={{ height: "100%" }}>
                  <TextBox
                    transitionProperty="opacity"
                    transitionTimingFunction="ease"
                    transitionDuration="md"
                    textAlign={alignText === "center" ? "center" : "left"}
                    textClassName="truncate-description-text"
                    className="text-box"
                    title={title}
                    descriptionRichText={descriptionRichText}
                    bg={"transparent"}
                    position="absolute"
                    width="100%"
                    left="0px"
                    bottom="0px"
                    overflow="hidden"
                    py={5}
                    px={5}
                    imageRatio={imageRatio}
                    minHeight={`${getTextBoxMinHeight(imageRatio)}%`}
                    display="flex"
                    justifyContent="flex-start"
                  />
                </div>
              )}
            </Box>
          </Flex>
        )}
      </StyledCard>
    </StyledLink>
  )
}

export default ImageCard

ImageCard.strapiProps = {
  title: PropTypes.string,
  description: PropTypes.shape(RichText.strapiProps),
  image: PropTypes.object.isRequired,
}

ImageCard.propTypes = {
  ...ImageCard.strapiProps,
  cardBackground: PropTypes.oneOf(["none", "0", "1"]),
  cardShadow: PropTypes.bool,
  align: PropTypes.oneOf(["left", "center"]),
  link: PropTypes.shape(Link.strapiProps),
}

export const query = graphql`
  fragment ImageCard on STRAPI__COMPONENT_MOLECULES_IMAGE_CARD {
    title
    descriptionRichText {
      ...RichText
    }
    image {
      ...ImageWithConstrainedLayout
    }
    link {
      ...Link
    }
    tags {
      name
    }
  }
`
